'use client'

import {createContext, useContext, useState} from 'react'

const GlobalStateContext = createContext()

function GlobalProvider({children}) {
  const [globalState, setGlobalState] = useState({
    theme: 'dark',
    navOpen: false
  })

  const toggleNav = () => setGlobalState({...globalState, navOpen: !globalState.navOpen})

  const closeNav = () => setGlobalState({ ...globalState, navOpen: false });

  return (
    <GlobalStateContext.Provider value={{globalState, setGlobalState, toggleNav, closeNav}}>
        {children}
    </GlobalStateContext.Provider>
  )
}

export const useGlobalState = () => useContext(GlobalStateContext)
export { GlobalProvider, GlobalStateContext }